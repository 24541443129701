// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block {
  position: relative;
  margin: 0 auto;
  width: 900px;
  height: 631px;
  background: linear-gradient(0deg, #000, #272727);
  z-index: 1;
}

.block:before,
.block:after {
  content: "";
  position: absolute;
  left: -4px;
  top: -4px;
  background: linear-gradient(45deg, #15ca7b, #4c9ddc, #15ca7b, #0e784a, #15ca7b, #4c9ddc, #15ca7b, #4c9ddc, #295476, #4c9ddc);
  background-size: 400%;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  z-index: -1;
  border-radius: 4px;
  animation: steam 60s linear infinite;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
.block:after {
  filter: blur(25px);
}`, "",{"version":3,"sources":["webpack://./src/pages/www/home-page/hero/index.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,gDAAA;EACA,UAAA;AACF;;AAEA;;EAEE,WAAA;EACA,kBAAA;EACA,UAAA;EACA,SAAA;EACA,4HAAA;EAaA,qBAAA;EACA,uBAAA;EACA,wBAAA;EACA,WAAA;EACA,kBAAA;EACA,oCAAA;AAXF;;AAcA;EACE;IACE,wBAAA;EAXF;EAaA;IACE,2BAAA;EAXF;EAaA;IACE,wBAAA;EAXF;AACF;AAcA;EACE,kBAAA;AAZF","sourcesContent":[".block {\r\n  position: relative;\r\n  margin: 0 auto;\r\n  width: 900px;\r\n  height: 631px;\r\n  background: linear-gradient(0deg, #000, #272727);\r\n  z-index: 1;\r\n}\r\n\r\n.block:before,\r\n.block:after {\r\n  content: \"\";\r\n  position: absolute;\r\n  left: -4px;\r\n  top: -4px;\r\n  background: linear-gradient(\r\n    45deg,\r\n    #15ca7b,\r\n    #4c9ddc,\r\n    #15ca7b,\r\n    #0e784a,\r\n    #15ca7b,\r\n    #4c9ddc,\r\n    #15ca7b,\r\n    #4c9ddc,\r\n    #295476,\r\n    #4c9ddc\r\n  );\r\n  background-size: 400%;\r\n  width: calc(100% + 8px);\r\n  height: calc(100% + 8px);\r\n  z-index: -1;\r\n  border-radius: 4px;\r\n  animation: steam 60s linear infinite;\r\n}\r\n\r\n@keyframes steam {\r\n  0% {\r\n    background-position: 0 0;\r\n  }\r\n  50% {\r\n    background-position: 400% 0;\r\n  }\r\n  100% {\r\n    background-position: 0 0;\r\n  }\r\n}\r\n\r\n.block:after {\r\n  filter: blur(25px);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
