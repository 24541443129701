// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:global .MuiToggleButton-root.Mui-selected {
  color: rgba(255, 255, 255, 0.6);
  border-color: rgba(255, 255, 255, 0.6) !important;
  background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #464c51 inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}`, "",{"version":3,"sources":["webpack://./src/static/buttons-dark.scss"],"names":[],"mappings":"AAEA;EACE,+BAHe;EAIf,iDAAA;EACA,6BAAA;AADF;;AAIA;;;;;;;;;EASE,8BAAA;EACA,gDAAA;EACA,yDAAA;EAAA,iDAAA;AADF","sourcesContent":["$selected-color: rgba(255, 255, 255, 0.6);\r\n\r\n:global .MuiToggleButton-root.Mui-selected {\r\n  color: $selected-color;\r\n  border-color: $selected-color !important;\r\n  background-color: transparent;\r\n}\r\n\r\ninput:-webkit-autofill,\r\ninput:-webkit-autofill:hover,\r\ninput:-webkit-autofill:focus,\r\ntextarea:-webkit-autofill,\r\ntextarea:-webkit-autofill:hover,\r\ntextarea:-webkit-autofill:focus,\r\nselect:-webkit-autofill,\r\nselect:-webkit-autofill:hover,\r\nselect:-webkit-autofill:focus {\r\n  -webkit-text-fill-color: white;\r\n  -webkit-box-shadow: 0 0 0px 1000px #464c51 inset;\r\n  transition: background-color 5000s ease-in-out 0s;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
